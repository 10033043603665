:root {
  --primary-color: #5e423e;
  --secondary-color: #f2ccc1;
  --extra-color: #d2d3d5;
  --accent-color: #eebc34;
  --success-color: #2ecc71;
  --error-color: #e74c3c;
}

/* Sets smooth scroll, and also to offset for navbar */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: var(--extra-color);
  color: #333;
  line-height: 1.6;
}

ul {
  list-style: none;
}

a {
  color: #333;
  text-decoration: none;
}

h1,
h2 {
  font-weight: 300;
  line-height: 1.2;
}

p {
  font-family: 'Raleway', sans-serif;
  margin: 10px 0;
}

img {
  width: 100%;
}

/* Navbar */
h1.logo,
h1.logo span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  opacity: 0.8;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0px;
  padding: 0 30px;
  transition: 0.5s;
}

.navbar img {
  width: 70px;
  margin-right: 20px;
}

.navbar.top {
  background: transparent;
}

.navbar a {
  position: relative;
  color: #fff;
  padding: 10px 20px;
  margin: 0 5px;
  transition: color 0.4s ease-out;
}

.navbar a::after {
  border-radius: 1em;
  border-top: 0.1em solid var(--accent-color);
  content: '';
  position: absolute;
  right: 100%;
  bottom: 0.14em;
  left: 0;
  transition: right 0.4s cubic-bezier(0, 0.5, 0, 1), border-color 0.4s ease-out;
}

.navbar a:hover {
  color: var(--accent-color);
  text-decoration: none;
}

.navbar a:hover::after {
  animation: anchor-underline 2s cubic-bezier(0, 0.5, 0, 1) infinite;
  border-color: var(--accent-color);
}

@keyframes anchor-underline {
  0%,
  10% {
    left: 0;
    right: 100%;
  }
  40%,
  60% {
    left: 0;
    right: 0;
  }
  90%,
  100% {
    left: 100%;
    right: 0;
  }
}

.navbar ul {
  display: flex;
}

.navbar .logo {
  font-weight: 400;
}

.navbar .logo span > i {
  margin-right: 10px;
}

/* Header */
.hero {
  background: url('../img/hero_lc.jpg') no-repeat center center/cover;
  height: 100vh;
  position: relative;
  color: #fff;
}

.hero .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 0 20px;
}

.hero .content h1 {
  font-size: 100px;
}

.hero .content i {
  font-size: 0.7em;
}

.hero .content p {
  font-size: 23px;
  max-width: 600px;
  margin: 20px 0 30px;
}

.hero .content a::before {
  text-decoration: none;
}

.hero .content a {
  opacity: 70%;
  transition: all 1.5s ease-in-out;
}

.hero .content a:hover {
  transition: all 0.8s ease-in-out;
  transform: scale(1.2);
  opacity: 100%;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.hero * {
  z-index: 10;
}

/* About */
#aboutImage {
  height: 100%;
}

#services h3 {
  font-size: 2em;
  font-style: italic;
}

/* Icons */
.icons {
  padding: 30px;
}

.icons h3 {
  font-weight: bold;
  margin-bottom: 15px;
}

.icons i {
  background-color: var(--accent-color);
  color: #fff;
  padding: 1rem;
  border-radius: 50%;
  margin-bottom: 15px;
}

.prevwork img:hover {
  opacity: 0.7;
}

/* Contact Buttons */
#contactBtn {
  margin-top: 15px;
}

#contactBtn:hover {
  transition: all 0.8s ease-in-out;
  transform: scale(1.05);
  background-color: #eed080;
}

/* Arrangements */
header.section-header {
  max-width: 1000px;
  margin: auto;
}

/* Callback form */
#contact img {
  width: 100%;
}

#contact .column-1 {
  display: flex;
  padding: 50px;
  margin: auto;
}

.form {
  width: 100%;
  padding: 20px 0;
}

.alertMessage {
  color: var(--extra-color);
  padding: 10px;
  border-radius: 6px;
  margin-left: auto !important;
  margin-top: 0 !important;
  background-color: var(--error-color);
}

.form-control label {
  display: block;
  margin-bottom: 5px;
}

.form-control input {
  width: 100%;
  padding: 4px;
  height: 40px;
  border: #f5f5f5 1px solid;
  border-radius: 5px;
}

.form-control textarea {
  width: 100%;
  padding: 4px;
  border: #f5f5f5 1px solid;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline-color: var(--accent-color);
}

#formtextarea {
  margin-top: 40px;
}

#send {
  width: 100%;
  padding: 12px 0;
  margin-top: 20px;
}

.form-control.success input {
  border-color: var(--success-color);
}

.form-control.error input {
  border-color: var(--error-color);
}

.form-control small {
  color: var(--error-color);
  position: relative;
  bottom: 0;
  left: 0;
  visibility: hidden;
}

.form-control.error small {
  visibility: visible;
}

.radio-buttons {
  font-family: 'Open Sans', sans-serif;
}

.radio-buttons input {
  margin-left: 20px;
}

/* Footer */
.footer {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  transition: all 0.5s ease-in-out;
  font-size: 1.2em;
  padding: 5px;
  color: var(--accent-color);
}

.footer p {
  padding-top: 20px;
}

.footer .social > * {
  margin-right: 30px;
}

/* Mobile */
@media (max-width: 768px) and (orientation: portrait) {
  /* Sets smooth scroll to offset for navbar */
  html {
    scroll-padding-top: 120px;
  }

  .navbar {
    flex-direction: column;
    height: 120px;
    padding: 15px;
  }

  .navbar a {
    padding: 10px 10px;
    margin: 0 3px;
  }

  .hero {
    background: url('../img/mobile/closeup26.jpg') no-repeat center center/cover;
  }

  .hero .content h1 {
    font-size: 2.2rem;
  }

  .hero .content p {
    font-size: 1rem;
  }

  .hero .content a {
    display: none;
  }

  .icons {
    padding: 20px;
  }

  .flex-items {
    flex-direction: column;
  }

  .cases.flex-grid.section-padding {
    padding: 5px;
  }

  .flex-columns .column,
  .flex-grid .column {
    flex: 50%;
    max-width: 100%;
  }

  .team img {
    width: 70%;
  }

  .card {
    width: 100%;
    border: none;
    border-radius: none;
    padding: 20px;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }

  #dispImg1,
  #dispImg2 {
    display: none;
  }

  .footer {
    height: 180px;
    padding-top: 30px;
  }
}

/* Mobile rotated */
@media (max-width: 812px) and (orientation: landscape) {
  /* Sets smooth scroll to offset for navbar */
  html {
    scroll-padding-top: 120px;
  }

  .navbar {
    flex-direction: column;
    height: 120px;
    padding: 15px;
  }

  .navbar a {
    padding: 10px 10px;
    margin: 0 3px;
  }

  .hero {
    background: url('../img/mobile/closeup26.jpg') no-repeat center center/cover;
  }

  .hero .content {
    justify-content: end;
    padding-bottom: 50px;
  }

  .hero .content h1 {
    font-size: 2.2rem;
  }

  .hero .content p {
    font-size: 1rem;
  }

  .hero .content a {
    display: none;
  }

  .icons {
    padding: 20px;
  }

  .flex-items {
    flex-direction: column;
  }

  .cases.flex-grid.section-padding {
    padding: 5px;
  }

  .flex-columns .column,
  .flex-grid .column {
    flex: 25%;
    max-width: 100%;
  }

  .team img {
    width: 70%;
  }

  .card {
    width: 100%;
    border: none;
    border-radius: none;
    padding: 20px;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }

  #dispImg1,
  #dispImg2 {
    display: none;
  }

  .footer {
    height: 180px;
    padding-top: 30px;
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 900px) {
  /* Sets smooth scroll to offset for navbar */
  html {
    scroll-padding-top: 120px;
  }

  #navbar.navbar {
    flex-direction: column;
    padding: 10px;
    height: 120px;
  }

  h1.logo {
    margin-bottom: 20px;
  }

  .hero .content h1 {
    font-size: 4rem;
  }

  .hero .content p {
    font-size: 1.2rem;
  }

  .hero .content a {
    display: none;
  }

  #about {
    justify-content: space-around;
  }

  .flex-columns .column,
  .flex-grid .column {
    flex: 50%;
    max-width: 100%;
  }

  .team img {
    width: 70%;
  }

  .card {
    flex-wrap: wrap;
    margin: 15px;
  }

  .services img {
    height: 100%;
  }

  .icons {
    display: flex;
  }

  #contact .column-1 {
    padding: 10px;
  }

  #dispImg1,
  #dispImg2 {
    display: none;
  }

  .footer {
    padding-top: 30px;
  }
}

/* Wide screen */
@media (min-width: 2560px) {
  .flex-columns .column,
  .flex-grid .column {
    flex: 10%;
    max-width: 100%;
  }
}

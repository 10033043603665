:root {
  --primary-color: #5e423e;
  --secondary-color: #f2ccc1;
  --extra-color: #d2d3d5;
  --accent-color: #eebc34;
  --success-color: #2ecc71;
  --error-color: #e74c3c;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: var(--extra-color);
  color: #333;
  font-family: Open Sans, sans-serif;
  line-height: 1.6;
}

ul {
  list-style: none;
}

a {
  color: #333;
  text-decoration: none;
}

h1, h2 {
  font-weight: 300;
  line-height: 1.2;
}

p {
  margin: 10px 0;
  font-family: Raleway, sans-serif;
}

img {
  width: 100%;
}

h1.logo, h1.logo span {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.navbar {
  color: #fff;
  opacity: .8;
  width: 100%;
  height: 70px;
  background-color: #333;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  transition: all .5s;
  display: flex;
  position: fixed;
  top: 0;
}

.navbar img {
  width: 70px;
  margin-right: 20px;
}

.navbar.top {
  background: none;
}

.navbar a {
  color: #fff;
  margin: 0 5px;
  padding: 10px 20px;
  transition: color .4s ease-out;
  position: relative;
}

.navbar a:after {
  border-top: .1em solid var(--accent-color);
  content: "";
  border-radius: 1em;
  transition: right .4s cubic-bezier(0, .5, 0, 1), border-color .4s ease-out;
  position: absolute;
  bottom: .14em;
  left: 0;
  right: 100%;
}

.navbar a:hover {
  color: var(--accent-color);
  text-decoration: none;
}

.navbar a:hover:after {
  border-color: var(--accent-color);
  animation: 2s cubic-bezier(0, .5, 0, 1) infinite anchor-underline;
}

@keyframes anchor-underline {
  0%, 10% {
    left: 0;
    right: 100%;
  }

  40%, 60% {
    left: 0;
    right: 0;
  }

  90%, 100% {
    left: 100%;
    right: 0;
  }
}

.navbar ul {
  display: flex;
}

.navbar .logo {
  font-weight: 400;
}

.navbar .logo span > i {
  margin-right: 10px;
}

.hero {
  height: 100vh;
  color: #fff;
  background: url("hero_lc.6f00d0d8.jpg") center / cover no-repeat;
  position: relative;
}

.hero .content {
  text-align: center;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  display: flex;
}

.hero .content h1 {
  font-size: 100px;
}

.hero .content i {
  font-size: .7em;
}

.hero .content p {
  max-width: 600px;
  margin: 20px 0 30px;
  font-size: 23px;
}

.hero .content a:before {
  text-decoration: none;
}

.hero .content a {
  opacity: .7;
  transition: all 1.5s ease-in-out;
}

.hero .content a:hover {
  opacity: 1;
  transition: all .8s ease-in-out;
  transform: scale(1.2);
}

.hero:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #0009;
  position: absolute;
  top: 0;
  left: 0;
}

.hero * {
  z-index: 10;
}

#aboutImage {
  height: 100%;
}

#services h3 {
  font-size: 2em;
  font-style: italic;
}

.icons {
  padding: 30px;
}

.icons h3 {
  margin-bottom: 15px;
  font-weight: bold;
}

.icons i {
  background-color: var(--accent-color);
  color: #fff;
  border-radius: 50%;
  margin-bottom: 15px;
  padding: 1rem;
}

.prevwork img:hover {
  opacity: .7;
}

#contactBtn {
  margin-top: 15px;
}

#contactBtn:hover {
  background-color: #eed080;
  transition: all .8s ease-in-out;
  transform: scale(1.05);
}

header.section-header {
  max-width: 1000px;
  margin: auto;
}

#contact img {
  width: 100%;
}

#contact .column-1 {
  margin: auto;
  padding: 50px;
  display: flex;
}

.form {
  width: 100%;
  padding: 20px 0;
}

.alertMessage {
  color: var(--extra-color);
  background-color: var(--error-color);
  border-radius: 6px;
  padding: 10px;
  margin-top: 0 !important;
  margin-left: auto !important;
}

.form-control label {
  margin-bottom: 5px;
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 4px;
}

.form-control textarea {
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 4px;
  font-family: Open Sans, sans-serif;
  font-size: .9rem;
}

.form-control input:focus, .form-control textarea:focus {
  outline-color: var(--accent-color);
}

#formtextarea {
  margin-top: 40px;
}

#send {
  width: 100%;
  margin-top: 20px;
  padding: 12px 0;
}

.form-control.success input {
  border-color: var(--success-color);
}

.form-control.error input {
  border-color: var(--error-color);
}

.form-control small {
  color: var(--error-color);
  visibility: hidden;
  position: relative;
  bottom: 0;
  left: 0;
}

.form-control.error small {
  visibility: visible;
}

.radio-buttons {
  font-family: Open Sans, sans-serif;
}

.radio-buttons input {
  margin-left: 20px;
}

.footer {
  text-align: center;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  display: flex;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  color: var(--accent-color);
  padding: 5px;
  font-size: 1.2em;
  transition: all .5s ease-in-out;
}

.footer p {
  padding-top: 20px;
}

.footer .social > * {
  margin-right: 30px;
}

@media (max-width: 768px) and (orientation: portrait) {
  html {
    scroll-padding-top: 120px;
  }

  .navbar {
    height: 120px;
    flex-direction: column;
    padding: 15px;
  }

  .navbar a {
    margin: 0 3px;
    padding: 10px;
  }

  .hero {
    background: url("closeup26.b7c07318.jpg") center / cover no-repeat;
  }

  .hero .content h1 {
    font-size: 2.2rem;
  }

  .hero .content p {
    font-size: 1rem;
  }

  .hero .content a {
    display: none;
  }

  .icons {
    padding: 20px;
  }

  .flex-items {
    flex-direction: column;
  }

  .cases.flex-grid.section-padding {
    padding: 5px;
  }

  .flex-columns .column, .flex-grid .column {
    max-width: 100%;
    flex: 50%;
  }

  .team img {
    width: 70%;
  }

  .card {
    width: 100%;
    border-radius: none;
    border: none;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 20px;
  }

  #dispImg1, #dispImg2 {
    display: none;
  }

  .footer {
    height: 180px;
    padding-top: 30px;
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  html {
    scroll-padding-top: 120px;
  }

  .navbar {
    height: 120px;
    flex-direction: column;
    padding: 15px;
  }

  .navbar a {
    margin: 0 3px;
    padding: 10px;
  }

  .hero {
    background: url("closeup26.b7c07318.jpg") center / cover no-repeat;
  }

  .hero .content {
    justify-content: end;
    padding-bottom: 50px;
  }

  .hero .content h1 {
    font-size: 2.2rem;
  }

  .hero .content p {
    font-size: 1rem;
  }

  .hero .content a {
    display: none;
  }

  .icons {
    padding: 20px;
  }

  .flex-items {
    flex-direction: column;
  }

  .cases.flex-grid.section-padding {
    padding: 5px;
  }

  .flex-columns .column, .flex-grid .column {
    max-width: 100%;
    flex: 25%;
  }

  .team img {
    width: 70%;
  }

  .card {
    width: 100%;
    border-radius: none;
    border: none;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 20px;
  }

  #dispImg1, #dispImg2 {
    display: none;
  }

  .footer {
    height: 180px;
    padding-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  html {
    scroll-padding-top: 120px;
  }

  #navbar.navbar {
    height: 120px;
    flex-direction: column;
    padding: 10px;
  }

  h1.logo {
    margin-bottom: 20px;
  }

  .hero .content h1 {
    font-size: 4rem;
  }

  .hero .content p {
    font-size: 1.2rem;
  }

  .hero .content a {
    display: none;
  }

  #about {
    justify-content: space-around;
  }

  .flex-columns .column, .flex-grid .column {
    max-width: 100%;
    flex: 50%;
  }

  .team img {
    width: 70%;
  }

  .card {
    flex-wrap: wrap;
    margin: 15px;
  }

  .services img {
    height: 100%;
  }

  .icons {
    display: flex;
  }

  #contact .column-1 {
    padding: 10px;
  }

  #dispImg1, #dispImg2 {
    display: none;
  }

  .footer {
    padding-top: 30px;
  }
}

@media (min-width: 2560px) {
  .flex-columns .column, .flex-grid .column {
    max-width: 100%;
    flex: 10%;
  }
}

/*# sourceMappingURL=index.73a06e76.css.map */
